import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import * as echarts from 'echarts';

import Video from 'video.js'
import 'video.js/dist/video-js.css'

// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'

// require styles 富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false

// 注册全局组件
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.prototype.$video = Video
// 注册富文本编辑器组件为全局组件
Vue.use(VueQuillEditor)

Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('../views/LoginView.vue')
const AdminHome = () => import('../views/admin/HomeView.vue')
const ProjectManage = () => import('../views/admin/ProjectManageView.vue')

const ActivityHome = () => import('../views/activity/ActivityHomeView.vue')
const ActivityIndex = () => import('../views/activity/ActivityIndexView.vue')
const ActivityRecruitManage = () => import('../views/activity/ActivityRecruitView.vue')
const ActivityEnrollManage = () => import('../views/activity/ActivityEnrollView.vue')
const ActivityExamineManage = () => import('../views/activity/examine/ActivityExamineView.vue')
const ActivityExamineControll = () => import('../views/activity/examine/ActivityExamineControllView.vue')
const ActivityExamineExaminee = () => import('../views/activity/examine/ActivityExamineExamineeView.vue')
const ActivityExamineExamineeVideo = () => import('../views/activity/examine/ActivityExamineExamineeVideoView.vue')
const ActivityExamineExamineeScheme = () => import('../views/activity/examine/ActivityExamineExamineeSchemeView.vue')
const ActivityExamineRoom = () => import('../views/activity/examine/ActivityExamineRoomView.vue')
const ActivityExamineMarking = () => import('../views/activity/examine/ActivityExamineMarkingView.vue')
const ActivityInterviewManage = () => import('../views/activity/ActivityInterviewView.vue')
const ActivityStaffManager = () => import('../views/activity/ActivityStaffView.vue')
const InitManage = () => import('../views/activity/ActivityInitView.vue')
const QuestionEdit = () => import('../views/QuestionEdit.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: '系统管理',
    component: AdminHome,
    children: [
      {
        path: '/admin/activity',
        name: '项目管理',
        component: ProjectManage,
      },
    ]
  }, {
    path: '/activity',
    name: '项目管理平台首页',
    redirect: '/activity/',
    component: ActivityHome,
    children: [
      {
        path: '/activity/',
        name: '项目信息设置',
        component: ActivityIndex,
      }, {
        path: '/activity/recruit',
        name: '招聘方案设置',
        component: ActivityRecruitManage,
      }, {
        path: '/activity/enroll',
        name: '报名方案设置',
        component: ActivityEnrollManage,
      }, {
        path: '/activity/examine',
        name: '考试设置与管理',
        component: ActivityExamineManage,
      }, {
        path: '/activity/examine/controll',
        name: '考中控制',
        component: ActivityExamineControll,
      }, {
        path: '/activity/examine/examinee',
        name: '考试考生管理',
        component: ActivityExamineExaminee,
      }, {
        path: '/activity/examine/examinee/scheme',
        name: '查看考生试卷',
        component: ActivityExamineExamineeScheme,
      }, {
        path: '/activity/examine/examinee/video',
        name: '查看考生监考视频',
        component: ActivityExamineExamineeVideo,
      }, {
        path: '/activity/examine/room',
        name: '考试考场管理',
        component: ActivityExamineRoom,
      }, {
        path: '/activity/examine/marking',
        name: '考试阅卷管理',
        component: ActivityExamineMarking,
      }, {
        path: '/activity/interview',
        name: '面试方案设置',
        component: ActivityInterviewManage,
      }, {
        path: '/activity/staff',
        name: '项目成员设置',
        component: ActivityStaffManager,
      }, {
        path: '/activity/init',
        name: '系统数据初始化',
        component: InitManage,
      }, {
        path: '/activity/questionEdit',
        name: '试题编辑',
        component: QuestionEdit,
      }]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

